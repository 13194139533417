.fade-in {
    animation: fadeIn ease .5s;
    -webkit-animation: fadeIn ease .5s;
    -moz-animation: fadeIn ease .5s;
    -o-animation: fadeIn ease .5s;
    -ms-animation: fadeIn ease .5s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-out {
    animation: fadeOut ease .5s;
    -webkit-animation: fadeOut ease .5s;
    -moz-animation: fadeOut ease .5s;
    -o-animation: fadeOut ease .5s;
    -ms-animation: fadeOut ease .5s;
    animation-fill-mode: forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

#shadowRoom,
#content {
    position: relative;
    //width: 100vw;
    // height: 100%;
    // margin: auto;
    // top: 0;
    // left: 0;
}

.room_wrapper {
    //width: 100vw;
    // min-height: 100vh;
    background-color: white;
}

nav {
    z-index: 3;
}

#content {
    z-index: 2;
}

.room_data {
    display: none;
}

.b-example-divider {
    position: relative;
    z-index: 4;
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.bi {
    vertical-align: -.125em;
    fill: currentColor;
}

.footerblock {
    position: relative;
    // bottom: 0;
    height: 60px;
    z-index: 4;
}

html {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
}

.main-body {
    display: grid;
    /* let content auto to occupy remaining height and pass value in fit-content with min-height for header and footer */
    grid-template-rows: fit-content(8rem) auto fit-content(8rem);
    grid-template-areas: "heading""main""footer";
}

.main-header {
    background-color: white;
    grid-area: heading;
}

.main-content {
    grid-area: main;
    background-color: white;
}

.main-footer {
    background-color: white;
    grid-area: footer;
}